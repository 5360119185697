<template>
  <div class="bkgd fill-height">
    <!-- v-if="authorizedAccess"  -->
    <Navbar :user="user" />
    <v-main class="mt-4">
      <router-view />
    </v-main>

    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "@/components/layout/registrationNavbar";
import Footer from "@/components/layout/footer";
import jwtdecode from "jwt-decode";

export default {
  name: "Home",
  components: {
    Navbar,
    Footer,
  },

  data() {
    return {
      authorizedAccess: false,
      dialog: false,
      user: "",
    };
  },

  created() {
    this.user = jwtdecode(this.$store.state.token, this.$store.state.accessKey);
    this.checkAccess();
  },
  methods: {
    checkAccess() {
      if (!this.user.accessLevel.isSuperAdmin) this.authorizedAccess = true;
    },
    redirect() {
      this.$router.push("/login");
    },
  },
};
</script>
<style>
.bkgd {
  background-color: #f3f6f9;
}
</style>
