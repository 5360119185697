<template>
  <nav>
    <!-- TOP BAR -->
    <v-app-bar app dense class="elevation-1 white">
      <v-card @click="reload()" class="transparent pa-2" flat>
        <v-img
          contain
          src="@/assets/Tlogo.png"
          width="120"
          height="auto"
        ></v-img>
      </v-card>

      <v-spacer></v-spacer>

      <div v-if="checkConnection()">
        <span v-if="user == null">{{ $t("loading...") }}</span>
      </div>

      <div v-else>
        <span class="font-weight-black red--text">{{
          $t("system is offline")
        }}</span>
      </div>
    </v-app-bar>

    <v-snackbar
      v-model="snackSuccess"
      bottom
      right
      color="success lighten-5 success--text text--darken-3"
      :timeout="4000"
    >
      <span>{{ $t("notification deleted") }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="success darken-3"
          @click="snackSuccess = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="snackError"
      bottom
      right
      color="red lighten-5 error--text text--darken-3"
      :timeout="4000"
    >
      <span>{{ $t("could not delete notification. Try again later") }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="error darken-3"
          @click="snackError = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </nav>
</template>

<script>
export default {
  props: ["user"],
  data: () => ({
    snackError: false,
    snackSuccess: false,

    internetConnection: "",
    userAccess: 0,

    busName: "",
  }),

  created() {
    window.addEventListener("online", this.checkConnection);
    window.addEventListener("offline", this.checkConnection);
  },

  methods: {
    checkConnection() {
      this.internetConnection = navigator.onLine ? true : false;
      return this.internetConnection;
    },

    reload() {
      this.$router.push("/");
    },
  },
};
</script>
